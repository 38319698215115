@media (prefers-color-scheme: dark) {
  html {
    --background: #151516;
    --foreground: #1e1e1f;
    --text: #fff;
    --border: #2a2a2b;
    --indent: #0d0d0d;
    --logo: url('../../public/logo-dark.svg');
  }
}

@media (prefers-color-scheme: light) {
  html {
    --background: #f9fafb;
    --foreground: #fff;
    --text: #000;
    --border: #e5e7eb;
    --indent: #e2e8f0;
    --logo: url('../../public/logo-light.svg');
  }
}

$color-primary: #1c74ac;
$border: 1px solid var(--border);
$radius: 0.25rem;
$shadow: 0 1px 3px 0 rgb(0 0 0 / 15%), 0 1px 2px 0 rgb(0 0 0 / 6%);
$color-indent: var(--indent);
$color-success: #38a169;
$color-error: #e53e3e;
$color-foreground: var(--foreground);
$color-background: var(--background);
$color-text: var(--text);
$logo: var(--logo);
