@import '../../styles/variables.scss';

.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  > img {
    width: 200px;
    content: $logo;
  }

  > .tile {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;

    > p {
      font-size: 1.2rem;
      font-weight: 700;
      opacity: 0.4;
      margin: 0.5rem;

      &.error {
        color: $color-error;
        font-size: 0.8rem;
        opacity: 1;
      }
    }

    > * {
      width: 100%;
    }
  }
}
