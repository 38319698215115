@import './variables.scss';

body {
  background-color: $color-background;
  color: $color-text;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  gap: 1rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 70px 1rem;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem 0;
}

.page-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

input,
select {
  background-color: $color-foreground;
  border: $border;
  padding: 0.5rem;
  border-radius: $radius;
  width: 100%;
  color: $color-text;
}

a {
  color: $color-primary;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    color: darken($color-primary, 30%);
    text-decoration: underline;
  }

  &.active {
    color: $color-primary;
    font-weight: 700;
  }

  &.inactive {
    color: gray;
  }
}

.tile {
  width: 100%;
  padding: 1rem;
  border: $border;
  border-radius: $radius;
  background-color: $color-foreground;
  margin: 1rem 0;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.form-row {
  display: flex;
  gap: 1rem;

  > * {
    flex: 1;
  }
}

.form-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0;
  gap: 1rem;
}

.logo-text {
  fill: #fff;
}

.active {
  color: $color-primary;
  font-weight: 700;
}

.inactive {
  color: $color-error;
  font-weight: 700;
}
