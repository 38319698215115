@import '../../styles/variables.scss';

.button {
  background-color: $color-primary;
  color: white;
  padding: 0.7rem;
  border: none;
  border-radius: $radius;
  transition: all 0.2s ease-in-out;
  font-weight: 700;
  min-width: 100px;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: $shadow;
  }

  &.secondary {
    background-color: $color-foreground;
    color: $color-primary;
    border: $border;
  }

  &.danger {
    background-color: $color-foreground;
    color: $color-error;
    border: $border;
  }

  &.success {
    background-color: $color-success;
    color: white;
  }
}
