@import '../../styles/variables.scss';

.header {
  background-color: $color-foreground;
  border: $border;
  height: 60px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 0 0.5rem;

  img {
    height: 35px;
    content: $logo;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p:last-of-type {
      font-size: 0.8rem;
      color: $color-primary;
      cursor: pointer;
    }
  }
}
