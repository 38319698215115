@import '../../styles/variables.scss';

.rich-input {
  background-color: $color-foreground;
  border: $border;
  padding: 0.5rem;
  border-radius: $radius;
  position: relative;

  .character-counter {
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-align: right;
    font-size: 0.8rem;
    opacity: 0.4;
  }

  .key {
    color: $color-primary;
    font-weight: 700;
  }

  > *:not(.character-counter),
  .public-DraftEditor-content {
    min-height: 200px;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
