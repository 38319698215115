@import '../../styles/variables.scss';

.schedule,
.rules {
  > div {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      font-weight: 500;
    }

    > input,
    > select {
      flex: 1;
    }
  }

  button {
    border: $border;
    border-radius: $radius;
    background-color: $color-foreground;
    color: $color-primary;
    transition: all 0.2s ease-in-out;
    height: 35px;
    width: 35px;
    font-weight: 700;

    &:hover {
      cursor: pointer;
      background-color: $color-indent;
      transform: scale(1.01);
    }
  }

  .add-rule {
    padding: 1rem;
    background-color: $color-indent;
    border-radius: $radius;
    border: $border;
    font-weight: 500;
    color: $color-primary;
    display: flex;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      background-color: $color-foreground;
      box-shadow: $shadow;
      transform: scale(1.01);
    }
  }
}

.field {
  transition: all 0.2s ease-in-out;
  padding: 0.5rem;
  border-radius: $radius;
  margin: 0.3rem 0;
  background-color: $color-foreground;
  font-size: small;

  &.used {
    color: $color-primary;
    font-weight: 700;
    background-color: $color-indent;
  }
}

.email-editor {
  display: flex;
  gap: 1rem;

  > * {
    flex: 3;
  }

  > .tile {
    flex: 1;
    margin: 0;
  }
}

.from-row {
  display: flex;
  gap: 1rem;
  align-items: center;

  > div {
    background-color: $color-foreground;
    border: $border;
    padding: 0.8rem;
    font-size: 0.8rem;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.active {
      border-color: $color-primary;
      color: $color-primary;
    }
  }
}
