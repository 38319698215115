@import '../../styles/variables.scss';

.journey-item {
  border: $border;
  background-color: $color-foreground;
  padding: 1rem;
  border-radius: $radius;
  transition: all 0.2s ease-in-out;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    flex: 1;
  }

  p {
    &.title {
      font-weight: 700;
      font-size: large;
    }
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: $shadow;
  }
}
