@import '../../styles/variables.scss';

.campaigns {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > p {
      font-size: 1.2rem;
      font-weight: 700;
      opacity: 0.4;
      margin: 0 1rem;
    }
  }
}
