@import '../../styles/variables.scss';

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    font-size: small;
    gap: 0.25rem;
  }

  p {
    color: gray;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: $color-primary;
      cursor: pointer;
      text-decoration: underline;
    }

    &:last-child {
      color: $color-primary;
      font-weight: 700;
    }
  }
}
